import React, { useContext, useEffect, useState } from 'react';
import { XIcon } from '@heroicons/react/solid'
import { CounterBanner } from './CounterBanner';
import { PromotionContext } from '../../pages/_app';
import sanitizeHtml from 'sanitize-html';

const Banner = ({ setVisibility, openFormPopup }) => {
    let { details, loading, promo } = useContext(PromotionContext);
    // const containerRef = useRef(null);
    const [show, setShow] = useState(false)
    // function useStateRef(processNode) {
    //     const [node, setNode] = useState(null);
    //     const setRef = useCallback(newNode => {
    //         setNode(processNode(newNode));
    //     }, [processNode]);
    //     return [node, setRef];
    // }

    // const [containerRef, setRef] = useStateRef(node => (node?.style || 0));

    // useEffect(() => {
    //     if(clientHeight != null){
    //         clientHeight.opacity = 0
    //     }
    //     console.log(`the new clientHeight is: ${clientHeight}`);
    //   }, [clientHeight])

    useEffect(() => {
        // if (containerRef != null) {
            if (promo && !loading) {
                setShow(true)
                // containerRef.display = 'block'
                // setTimeout(() => containerRef.opacity = 100, 20);
            } else {
                setShow(false)
                // setTimeout(() => containerRef.opacity = 0, 20);
                // containerRef.display = 'none'
            }
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, promo]);

    const closeBanner = () => {
        // if (containerRef != null) {
            setVisibility(false)
            setShow(false)
        // }
    }

    const DetailsText = () => {
        const dirty = details.text != null ? details.text : '';
        const clean = sanitizeHtml(dirty, {
            allowedTags: ['b', 'i', 'em', 'strong', 'a', 'del', 'div'],
            allowedAttributes: {
                a: ['href', 'target'],
                div: ['style', 'classname']
            }
        });
        return (
            <div
                dangerouslySetInnerHTML={{ __html: clean }}
            />
        );
    };

    return (
        <div className={`${show ? 'visible-div' : 'hidden-div'}`}>
            <div className={`bg-[#C43815] w-full `} >
                <div className=" items-center max-w-screen-lg lg:max-w-[1180px] justify-between px-2 mx-auto bg-[#C43815] py-4 gap-4 lg:flex hidden">
                    <span className="text-base leading-6 text-white font-quicksand  supx:pl-20 font-normal">
                        <DetailsText />
                    </span>

                    <div className='flex gap-8 items-center justify-center'>
                        <CounterBanner />
                        <button className='w-52 bg-white px-4 py-3 rounded-full font-bold text-red-hot-sale font-quicksand' onClick={openFormPopup}>
                            EMPIEZA YA
                        </button>
                    </div>

                    <button type="button" className="-m-3 flex-none p-3 focus-visible:outline-offset-[-4px]" onClick={closeBanner}>
                        <span className="sr-only">Dismiss</span>
                        <XIcon className="h-5 w-5 text-white" />
                    </button>
                </div>

            </div>
        </div>
    );
}
export default Banner;