
import Image from 'next/image';
import { MailIcon } from '@heroicons/react/solid'
import Loader from '../Hooks/Loader';
import RouteLoader from '../Common/RouteLoader';
import Input from '../Hooks/Input'
import { useForm } from 'react-hook-form';
import { useContext, useEffect, useRef, useState } from 'react';
import { PromotionContext } from '../../pages/_app';
import Dropdown from '../Hooks/Dropdown';
import { createSessionMetadata } from '../../apiClient/operations/sessionOperations';
import { getAdsParameters, onLeadError } from '../../util/util';
import { useRouter } from 'next/router';
import FormBannerMobile from '../Promos/FormBannerMobile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const Form = ({ onClose, signupLoading, setSignupLoading, mailValue, title, buttonLabel, page }) => {
  let { promo } = useContext(PromotionContext);
  const form = useForm();
  const [submited, setSubmited] = useState(false);
  const { handleSubmit, register, reset, watch, trigger, formState: { errors, dirtyFields }, setValue } = form
  const buttonNameRef = useRef();
  const buttonPhoneRef = useRef();
  const buttonEmailRef = useRef();
  const boxLateral = useRef();
  const formBoxLateral = useRef();
  const [error, setError] = useState(null);
  const paramsCookieName = "fixat_utm_params";
  const [referrer, setReferrer] = useState('');
  const router = useRouter();

  useEffect(() => {
    setReferrer(document?.referrer || '');
  }, []);

  const onRouteChangeComplete = loading => {
    setSignupLoading(loading);
    reset();
  }

  function isFieldValid(form, field) {
    return form.formState.dirtyFields[field] && !form.formState.errors[field] && submited
  }
  useEffect(() => {
    if (mailValue != '' || mailValue != undefined) {
      setValue('email', mailValue)
    }
  }, [mailValue, setValue]);

  const scrollFunction = (ref) => {
    if (!ref.current) return;
    ref.current.scrollIntoView({ behavior: "smooth" });
  }

  const onSubmit = (data, e) => {
    e.preventDefault();
    const { name, email, phone, bill, occupancy } = data;
    const is_billing = parseInt(bill)
    if (error) { setError(null) }
    setSignupLoading(true);
    const campaign_ads = getAdsParameters(paramsCookieName, router);
    const source = campaign_ads?.utm_source || "rforganic";
    campaign_ads['referrer'] = referrer;
    createSessionMetadata(name, email, `+52${phone.replace(/\s+/g, '')}`, source, campaign_ads, is_billing, occupancy).then(response => {
      if (is_billing != 0) {
        window.gtag('event', 'subscribe');
        window._fbq.push('track', 'Subscribe');
      }
      localStorage.setItem('lead', JSON.stringify(response));
      console.log(response)
      router.push(page);
    }).catch(error => {
      const { message, loadingSignup } = onLeadError(error, data)
      if (window.innerWidth < 600) {
        scrollFunction(formBoxLateral)
      } else {
        scrollFunction(boxLateral)
      }
      setError(message);
      setSignupLoading(loadingSignup);
    });
  }

  const wName = watch('name')
  useEffect(() => {
    if (dirtyFields.name === true && submited) {
      buttonNameRef.current.click()
    }

  }, [wName, dirtyFields.name, submited]);

  const wPhone = watch('phone');
  useEffect(() => {
    if (dirtyFields.phone === true & submited) {
      buttonPhoneRef.current.click()
    }

  }, [wPhone, dirtyFields.phone, submited]);

  const wEmail = watch('email');
  useEffect(() => {
    if (dirtyFields.email === true && submited) {
      buttonEmailRef.current.click()
    }

  }, [wEmail, dirtyFields.email, submited]);

  // const price = () => {
  //   if (promo == false && details.new_price == null) {
  //     return process.env.PRICE_ASF;
  //   } else {
  //     return details.new_price;
  //   }
  // }

  const showErrors = () => {
    setSubmited(true)
    trigger('name')
    trigger('email')
    trigger('phone')
  }

  return (
    <div className={`pt-12 smx:pt-14 ssm:px-4 relative bg-white w-[600px] h-screen flex flex-col px-12 pb-4 box-border items-center justify-start  gap-[12px] max-w-[538px] text-center text-sm text-steelblue-500 font-text-sm-leading-5-font-normal mmd:min-w-full ssm:w-full ssm:[align-self:unset]  ssm:box-border overflow-y-scroll`}>
      {signupLoading && (<Loader />)}
      <div className='fixed top-7 right-7 ssm:top-4 ssm:right-4 ssm:absolute'>
        <button onClick={onClose}><FontAwesomeIcon size='2x' className='text-base' icon={faXmark} /></button>
      </div>
      <div className='font-quicksand hidden mmd:block'>
        {promo && <FormBannerMobile />}
      </div>
      {/* <div className="self-stretch flex flex-col py-3.5 pr-0 pl-10 items-center justify-center relative text-white mmd:pl-10 mmd:pr-10 mmd:box-border ssm:pl-[76px] ssm:pr-5 ssm:box-border">
        <div className="self-stretch rounded-xl pl-6 mmd:pl-9 ssm:pl-3 bg-red h-7 mmd:h-fit flex flex-row items-center justify-center gap-[6px] z-[0] ssm:items-start ssm:justify-start">
          <div className="relative leading-[20px] text-left">
            <span className="font-normal leading-[20px] font-text-sm-leading-5-font-normal text-sm ">
              <b>¡Últimas horas!</b> Conoce tu estatus fiscal a sólo ${price()}
            </span>
          </div>
        </div>
        <div className="absolute my-0 mx-[!important] top-[-11px] left-[-10px] ssm:left-0 ssm:top-[-8px] object-cover z-[1]">
          <Image
            loading="lazy"
            alt="fixipromo"
            width={81}
            height={84}
            src={'https://cdn.fixat.mx/assets/home/img/fixipromo.png'}
          />
        </div>
      </div> */}
      <div className='ssm:px-2 text-2xl -mt-2 font-fredoka-one text-steelblue-500 mmd:max-w-none' ref={boxLateral}>
        {title}
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="ssm:px-2 w-full flex flex-col items-center justify-start gap-[14px] text-left font-quicksand" ref={formBoxLateral}>
        <Input
          label='Correo electrónico'
          type='email'
          autofocus
          id='email'
          name='email'
          placeholder={'Ej. mail@correo.mx'}
          needed
          leftIcon={<MailIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
          pattern={/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i}
          minLength={6}
          register={register}

          errorsText={['Ingresa tu correo electrónico, por favor.', 'El correo electónico no es válido', 'El correo electrónico debe tener mínimo 11 caracteres']}
          isValid={isFieldValid(form, 'email')}
          errors={errors}
        />
        <Input
          label='Nombre completo'
          type='text'
          id='name'
          name='name'
          placeholder={'Ej. María Félix García Sánchez'}
          needed
          pattern={/^[A-Za-zÑñÁÉÍÓÚáéíóúÜü,. -']{1,45}$|^$/}
          minLength={8}
          register={register}
          errorsText={['Ingresa tu nombre completo, por favor.', 'El nombre completo no es válido, no ingreses números', 'El nombre completo debe tener 8 caracteres']}
          isValid={isFieldValid(form, 'name')}
          errors={errors}
        />
        <Input
          label='Número de WhatsApp'
          type='tel'
          id='phone'
          name='phone'
          placeholder={'5559876543'}
          needed
          pattern={/^[0-9]+$/}
          minLength={10}
          maxLength={10}
          register={register}
          errorsText={['Ingresa tu número de teléfono, por favor.', 'El número de teléfono no es válido, ingresa solo números', 'El número de telefono debe tener 10 caracteres']}
          isValid={isFieldValid(form, 'phone')}
          errors={errors}

        />
        <Input
          label='¿A qué te dedicas? '
          type='text'
          id='occupancy'
          name='occupancy'
          placeholder={'Agrega tu actividad profesional'}
          needed
          pattern={/^[A-Za-zÑñÁÉÍÓÚáéíóúÜü,. -']{5,45}$|^$/}
          minLength={5}
          register={register}
          errorsText={['Ingresa tu ocupación, por favor.', 'La ocupación no es válida', 'La ocupación debe tener al menos 5 carácteres']}
          isValid={isFieldValid(form, 'occupancy')}
          errors={errors}
        />
        <Dropdown
          label='¿Generas facturas a tus clientes?'
          id='bill'
          name='bill'
          needed
          isValid={isFieldValid(form, 'bill')}
          register={register}
          errors={errors}
          options={[{ label: "Sí facturo", value: 1 }, { label: "No facturo", value: 0 }, { label: "No, pero me urge hacer una factura", value: 2 }]}
        />
        <button className='hidden' type='button' ref={buttonNameRef} onClick={() => { trigger('name') }}>
        </button>
        <button className='hidden' type='button' ref={buttonPhoneRef} onClick={() => { trigger('phone') }}>
        </button>
        <button className='hidden' type='button' ref={buttonEmailRef} onClick={() => { trigger('email') }}>
        </button>
        <button className="mt-3 cursor-pointer [border:none] py-4 px-[30px] md:hover:bg-hover-orange bg-button-orange self-stretch rounded-81xl flex flex-row items-center justify-center ssm:items-center ssm:justify-center" type='submit' onClick={showErrors}>
          {signupLoading ? (
            <RouteLoader setLoading={onRouteChangeComplete} />
          )
            : (
              <b className="relative text-base font-quicksand font-bold text-blue-new-button-text text-left ssm:text-center">{buttonLabel}</b>)
          }
        </button>
        {error &&
          <div className="flex w-full mt-2">
            <span className="w-full help-block-error">{error}</span>
          </div>
        }
      </form>

      <div className="ssm:px-2 w-full mt-2 mmd:mt-1 flex flex-row items-center justify-between gap-6 text-left text-base text-negro-textos ssm:w-full">
        <div className="w-fit"><p className='text-base text-pretty font-medium font-quicksand'>{`Tus datos personales 100% protegidos`}</p></div>
        <div className="relative w-[73px] h-14 object-cover">
          <Image
            loading="lazy"
            layout='fill'
            alt="lets_encrypted"
            src="https://cdn.fixat.mx/assets/home/img/lets_encrypt.png"
          />
        </div>
      </div>
    </div>
  );
};

export default Form;
